import React from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { Card, Spin } from 'antd'

import { Heading, TypographyComponent } from 'components/Typography'

import './style.css'

interface GeneralViewCardProps {
  readonly header: {
    title: string
    subTitle?: string
    extra?: React.ReactNode
  }
  readonly leftActions?: React.ReactNode
  readonly rightActions?: React.ReactNode
  readonly children: React.ReactNode
  readonly fetching?: boolean
}

interface CardHeaderProps {
  readonly title: string
  readonly subTitle?: string
  readonly extra?: React.ReactNode
}

export const CardHeader: React.FC<CardHeaderProps> = ({ title, subTitle, extra }) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: '1rem',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'last baseline' }}>
        <Heading headingType="h1" text={title} />
        <TypographyComponent
          text={subTitle ?? ''}
          emphasis="low"
          size="small"
          fontWeight="normal"
        />
      </div>
      <div>{extra}</div>
    </div>
  )
}

// Use this component for views accessed through the menu (Admin is the exception here)
export const GeneralViewCard: React.FC<GeneralViewCardProps> = ({
  header,
  children,
  fetching,
  leftActions,
  rightActions,
}) => {
  const { title = '', subTitle, extra } = header

  return (
    <Card>
      <div className="general">
        <CardHeader title={title} subTitle={subTitle} extra={extra} />
        {leftActions || rightActions ? (
          <div className="general-view-card-actions">
            <div className="general__actions">{!!leftActions && leftActions}</div>
            <div className="general__actions">{!!rightActions && rightActions}</div>
          </div>
        ) : null}
        {typeof fetching !== undefined && fetching ? (
          <div style={{ textAlign: 'center', margin: 48 }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin={true} />} />
          </div>
        ) : (
          children
        )}
      </div>
    </Card>
  )
}

interface CommonLayoutProps {
  children: JSX.Element
}
export const CommonLayout = ({ children }: CommonLayoutProps) => {
  return <Card className="general">{children}</Card>
}
