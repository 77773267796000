import * as lib from 'country-data'

import * as currencyv1 from '../proto/currency/v1/currency_pb'
import * as branchv1 from '../proto/iam/v1/branch_pb'
import * as organizationv1 from '../proto/iam/v1/organization_pb'
import * as costv1 from '../proto/invoicing/v1/cost_pb'

export const sourceTypes = ['EXTERNAL', 'INTERNAL']

export type RateContextType = 'INVOICE' | 'BRANCH'

export const rateContextValue = {
  INVOICE: 'INVOICE' as RateContextType,
  BRANCH: 'BRANCH' as RateContextType,
}

export const currencyMultiplier = 10000
export const currencyRateMultiplier = 1000000

export const InterBranchInvoiceCurrency = 'EUR'

export const sourceTypeLabels: { [key in currencyv1.CurrencyRate.SourceType]: string } = {
  [currencyv1.CurrencyRate.SourceType.UNKNOWN]: 'UNKNOWN',
  [currencyv1.CurrencyRate.SourceType.INTERNAL]: 'INTERNAL',
  [currencyv1.CurrencyRate.SourceType.EXTERNAL]: 'EXTERNAL',
}

const notAcceptedCurrencies: { [key: string]: boolean } = {
  BOV: true, // Bolivian Mvdol (funds code)
  CHE: true, // WIR euro (complementary currency)
  CHW: true, // WIR franc (complementary currency)
  CLF: true, // Unidad de Fomento (funds code)
  COU: true, // Unidad de Valor Real (UVR) (funds code)
  MXV: true, // Mexican Unidad de Inversion (UDI) (funds code)
  USN: true, // United States dollar (next day) (funds code)
  USS: true, // United States dollar (same day) (funds code)
  UYI: true, //	Uruguay Peso en Unidades Indexadas (URUIURUI) (funds code)
  UYW: true, // Unidad previsional
  XAG: true, // Silver (one troy ounce)
  XAU: true, // Gold (one troy ounce)
  XBA: true, // European Composite Unit (EURCO) (bond market unit)
  XBB: true, // European Monetary Unit (E.M.U.-6) (bond market unit)
  XBC: true, // European Unit of Account 9 (E.U.A.-9) (bond market unit)
  XBD: true, // European Unit of Account 17 (E.U.A.-17) (bond market unit)
  XDR: true, // Special drawing rights
  XFU: true, // UIC franc (special settlement currency)
  XOF: true, // CFA franc BCEAO
  XPD: true, // Palladium (one troy ounce)
  XPT: true, // Platinum (one troy ounce)
  XSU: true, // Unified System for Regional Compensation (SUCRE)
  XTS: true, // Code reserved for testing
  XUE: true, // ADB Unit of Account African Development Bank[
  XXX: true, // No currency
}

// Get the currencies accepted by Elise.
export function getEliseAcceptedCurrencies(branches: branchv1.Branch[]): lib.Currency[] {
  const currencies = new Set<string>(branches.map((b) => b.getAcceptedCurrenciesList()).flat())
  if (currencies.size === 0) {
    return lib.currencies.all.filter((c) => !notAcceptedCurrencies[c.code])
  }
  return lib.currencies.all.filter((c) => currencies.has(c.code))
}

// Get default currency based on branch.
export function getBranchDefaultCurrency(branch?: branchv1.Branch): lib.Currency | undefined {
  if (!branch) {
    return
  }
  const defaultCurrency = branch.getDefaultCurrency()
  if (!defaultCurrency) {
    return
  }
  return getEliseAcceptedCurrencies([]).find((c) => c.code === defaultCurrency)
}

// Get accepted currency based on branch.
export function getBranchAcceptedCurrencies(branch?: branchv1.Branch): lib.Currency[] {
  if (!branch) {
    return getEliseAcceptedCurrencies([])
  }
  return getEliseAcceptedCurrencies([branch])
}

// Get the currency for the organization or return Eur as default.
export function getCurrencyFromOrg(org?: organizationv1.Organization): string {
  const defaultCurrency = 'EUR'
  if (!org) {
    return defaultCurrency
  }

  const config = org.getConfig()
  if (!config) {
    return defaultCurrency
  }

  const currency = getEliseAcceptedCurrencies([]).find(
    (c) => config.getDefaultCurrency() === c.code,
  )
  if (!currency) {
    return defaultCurrency
  }

  return currency.code
}

export function currencyAndCostDoNotMatch(
  a: currencyv1.ConvertCurrency[],
  b: costv1.Cost[],
): boolean {
  if (b.length === 0) {
    return false
  }
  if (a.length === 0) {
    return true
  }
  if (a.length !== b.length) {
    return true
  }
  return a
    .map((i) => {
      const j = b.find((k) => k.getCostId() === i.getContextId())
      if (!j) {
        return true
      }
      const amount = j.getActualAmount() || j.getEstimatedAmount()
      return amount !== i.getAmount()
    })
    .some((c) => c)
}

export const currencyToInput = (currency: number): number => {
  return currency / 100
}

export const currencyFromInput = (currency: number): number =>
  convertToInternalCurrencyRepresentation(currency)

export function convertToInternalCurrencyRepresentation(n: number): number {
  const p = Math.pow(10, 2)
  const num = n * p * (1 + Number.EPSILON) * 100
  return Math.round(num) / p
}
export function convertToInternalCurrencyRateRepresentation(n: number): number {
  const p = Math.pow(10, 4)
  const num = n * p * (1 + Number.EPSILON) * 10000
  return Math.round(num) / p
}
