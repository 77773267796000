import React, { useEffect } from 'react'

import { Form, InputNumber } from 'antd'
import { FormInstance, Rule } from 'antd/es/form'

import { runRules } from '../helpers/CustomValidator.helper'
import { getInputClassName } from '../helpers/Input.helper'

import { InputCommonProps } from '../types/InputCommonProps.interface'
import { INPUTSIZES } from '../types/InputSize.type'

import './Input.css'

interface NumberInputProps extends InputCommonProps {
  value?: number | null
  onChangeHandler: (value: number) => void
  allowDelimiter?: boolean
  precision?: number
  min?: number
  max?: number
  step?: number
  placeholder?: string
  addonBefore?: React.ReactNode
  addonAfter?: React.ReactNode
  formName?: string
  label?: string
  formRef?: FormInstance<any>
  rules?: Rule[]
}

/**
 * Usage: When only number as input is allowed.
 */
export const NumberInput = ({
  value,
  onChangeHandler,
  allowDelimiter = false,
  precision = 2,
  min = 0,
  step = 1,
  size = INPUTSIZES.medium,
  overrideWidth,
  disabled,
  placeholder,
  addonBefore,
  addonAfter,
  formName,
  label,
  rules,
  formRef,
}: NumberInputProps) => {
  const allowedCharacters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-']
  const defaultDelimiter = ','

  /**
   * Runs before the value is written to the input.
   * Prevent default stops the value from being written. Basically means that nothing has happened.
   */
  const onBeforeInput = (event: React.CompositionEvent) => {
    const split = event.data?.split('')
    const allowedChars = allowDelimiter
      ? [...allowedCharacters, defaultDelimiter]
      : allowedCharacters

    const valueIsAllowed = split?.every((s) => allowedChars.includes(s))
    if (!valueIsAllowed) {
      event.preventDefault()
    }
  }

  const onChange = (value: number | null) => {
    onChangeHandler(value as number)
  }

  useEffect(() => {
    if (formRef && formName) {
      formRef.setFieldsValue({
        [formName]: value,
      })
    }
  }, [value])

  return formName ? (
    <Form.Item
      className={overrideWidth ? '' : getInputClassName(size) + ' inter__input--height'}
      label={label}
      name={formName}
      required={rules?.some((rule: unknown) => {
        if (typeof rule === 'object' && rule !== null && 'required' in rule) {
          return rule.required === true
        }
      })}
      rules={[
        {
          validator() {
            return runRules(rules)
          },
        },
      ]}
    >
      <InputNumber
        addonAfter={addonAfter}
        addonBefore={addonBefore}
        placeholder={placeholder}
        disabled={disabled}
        min={min || undefined}
        precision={allowDelimiter ? precision : 0}
        className={overrideWidth ? '' : getInputClassName(size) + ' inter__input--height'}
        width={overrideWidth}
        step={step}
        decimalSeparator={defaultDelimiter}
        onBeforeInput={(ev: React.SyntheticEvent) => onBeforeInput(ev as React.CompositionEvent)}
        value={value}
        onChange={onChange}
      />
    </Form.Item>
  ) : (
    <InputNumber
      defaultValue={value || undefined}
      addonAfter={addonAfter}
      addonBefore={addonBefore}
      placeholder={placeholder}
      disabled={disabled}
      min={min}
      precision={allowDelimiter ? precision : 0}
      className={overrideWidth ? '' : getInputClassName(size)}
      width={overrideWidth}
      step={step}
      decimalSeparator={defaultDelimiter}
      onBeforeInput={(ev: React.SyntheticEvent) => onBeforeInput(ev as React.CompositionEvent)}
      value={value}
      onChange={onChange}
    />
  )
}
