// source: contract/v1/contract.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var common_booking_pb = require('../../common/booking_pb.js');
goog.object.extend(proto, common_booking_pb);
var common_contract_pb = require('../../common/contract_pb.js');
goog.object.extend(proto, common_contract_pb);
var common_filter_pb = require('../../common/filter_pb.js');
goog.object.extend(proto, common_filter_pb);
var crm_v1_address_pb = require('../../crm/v1/address_pb.js');
goog.object.extend(proto, crm_v1_address_pb);
var common_query_pb = require('../../common/query_pb.js');
goog.object.extend(proto, common_query_pb);
goog.exportSymbol('proto.swift.contract.v1.Breakpoint', null, global);
goog.exportSymbol('proto.swift.contract.v1.ConfiguredSurcharge', null, global);
goog.exportSymbol('proto.swift.contract.v1.Contract', null, global);
goog.exportSymbol('proto.swift.contract.v1.Contract.Status', null, global);
goog.exportSymbol('proto.swift.contract.v1.ContractFilter', null, global);
goog.exportSymbol('proto.swift.contract.v1.ContractSorting', null, global);
goog.exportSymbol('proto.swift.contract.v1.ContractSorting.Field', null, global);
goog.exportSymbol('proto.swift.contract.v1.ContractSorting.Ordering', null, global);
goog.exportSymbol('proto.swift.contract.v1.CreateContractRequest', null, global);
goog.exportSymbol('proto.swift.contract.v1.CreateContractResponse', null, global);
goog.exportSymbol('proto.swift.contract.v1.CreateSurchargeRequest', null, global);
goog.exportSymbol('proto.swift.contract.v1.CreateSurchargeResponse', null, global);
goog.exportSymbol('proto.swift.contract.v1.DateFilter', null, global);
goog.exportSymbol('proto.swift.contract.v1.DateFilter.Field', null, global);
goog.exportSymbol('proto.swift.contract.v1.DateFilter.Relative', null, global);
goog.exportSymbol('proto.swift.contract.v1.DateFilter.ValueCase', null, global);
goog.exportSymbol('proto.swift.contract.v1.GetContractRequest', null, global);
goog.exportSymbol('proto.swift.contract.v1.GetContractResponse', null, global);
goog.exportSymbol('proto.swift.contract.v1.Lane', null, global);
goog.exportSymbol('proto.swift.contract.v1.Lane.CalculationType', null, global);
goog.exportSymbol('proto.swift.contract.v1.Lane.LaneTypeCase', null, global);
goog.exportSymbol('proto.swift.contract.v1.ListContractsRequest', null, global);
goog.exportSymbol('proto.swift.contract.v1.ListContractsResponse', null, global);
goog.exportSymbol('proto.swift.contract.v1.ListSurchargesRequest', null, global);
goog.exportSymbol('proto.swift.contract.v1.ListSurchargesResponse', null, global);
goog.exportSymbol('proto.swift.contract.v1.NominatedContract', null, global);
goog.exportSymbol('proto.swift.contract.v1.RoadTypes', null, global);
goog.exportSymbol('proto.swift.contract.v1.RoadTypes.SpaceUnit', null, global);
goog.exportSymbol('proto.swift.contract.v1.RoadTypes.TransportSubmode', null, global);
goog.exportSymbol('proto.swift.contract.v1.Surcharge', null, global);
goog.exportSymbol('proto.swift.contract.v1.Surcharge.Category', null, global);
goog.exportSymbol('proto.swift.contract.v1.Surcharge.RateType', null, global);
goog.exportSymbol('proto.swift.contract.v1.SurchargeFilter', null, global);
goog.exportSymbol('proto.swift.contract.v1.SurchargeSorting', null, global);
goog.exportSymbol('proto.swift.contract.v1.SurchargeSorting.Field', null, global);
goog.exportSymbol('proto.swift.contract.v1.SurchargeSorting.Ordering', null, global);
goog.exportSymbol('proto.swift.contract.v1.UpdateContractRequest', null, global);
goog.exportSymbol('proto.swift.contract.v1.UpdateContractResponse', null, global);
goog.exportSymbol('proto.swift.contract.v1.UpdateSurchargeRequest', null, global);
goog.exportSymbol('proto.swift.contract.v1.UpdateSurchargeResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.Contract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.contract.v1.Contract.repeatedFields_, null);
};
goog.inherits(proto.swift.contract.v1.Contract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.Contract.displayName = 'proto.swift.contract.v1.Contract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.Lane = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.contract.v1.Lane.repeatedFields_, proto.swift.contract.v1.Lane.oneofGroups_);
};
goog.inherits(proto.swift.contract.v1.Lane, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.Lane.displayName = 'proto.swift.contract.v1.Lane';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.RoadTypes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.contract.v1.RoadTypes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.RoadTypes.displayName = 'proto.swift.contract.v1.RoadTypes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.Breakpoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.contract.v1.Breakpoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.Breakpoint.displayName = 'proto.swift.contract.v1.Breakpoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.NominatedContract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.contract.v1.NominatedContract.repeatedFields_, null);
};
goog.inherits(proto.swift.contract.v1.NominatedContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.NominatedContract.displayName = 'proto.swift.contract.v1.NominatedContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.ConfiguredSurcharge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.contract.v1.ConfiguredSurcharge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.ConfiguredSurcharge.displayName = 'proto.swift.contract.v1.ConfiguredSurcharge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.Surcharge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.contract.v1.Surcharge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.Surcharge.displayName = 'proto.swift.contract.v1.Surcharge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.ContractFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.contract.v1.ContractFilter.repeatedFields_, null);
};
goog.inherits(proto.swift.contract.v1.ContractFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.ContractFilter.displayName = 'proto.swift.contract.v1.ContractFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.DateFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.swift.contract.v1.DateFilter.oneofGroups_);
};
goog.inherits(proto.swift.contract.v1.DateFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.DateFilter.displayName = 'proto.swift.contract.v1.DateFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.ContractSorting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.contract.v1.ContractSorting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.ContractSorting.displayName = 'proto.swift.contract.v1.ContractSorting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.SurchargeFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.contract.v1.SurchargeFilter.repeatedFields_, null);
};
goog.inherits(proto.swift.contract.v1.SurchargeFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.SurchargeFilter.displayName = 'proto.swift.contract.v1.SurchargeFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.SurchargeSorting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.contract.v1.SurchargeSorting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.SurchargeSorting.displayName = 'proto.swift.contract.v1.SurchargeSorting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.ListContractsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.contract.v1.ListContractsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.ListContractsRequest.displayName = 'proto.swift.contract.v1.ListContractsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.ListContractsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.contract.v1.ListContractsResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.contract.v1.ListContractsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.ListContractsResponse.displayName = 'proto.swift.contract.v1.ListContractsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.GetContractRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.contract.v1.GetContractRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.GetContractRequest.displayName = 'proto.swift.contract.v1.GetContractRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.GetContractResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.contract.v1.GetContractResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.GetContractResponse.displayName = 'proto.swift.contract.v1.GetContractResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.CreateContractRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.contract.v1.CreateContractRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.CreateContractRequest.displayName = 'proto.swift.contract.v1.CreateContractRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.CreateContractResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.contract.v1.CreateContractResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.CreateContractResponse.displayName = 'proto.swift.contract.v1.CreateContractResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.CreateSurchargeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.contract.v1.CreateSurchargeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.CreateSurchargeRequest.displayName = 'proto.swift.contract.v1.CreateSurchargeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.CreateSurchargeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.contract.v1.CreateSurchargeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.CreateSurchargeResponse.displayName = 'proto.swift.contract.v1.CreateSurchargeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.UpdateContractRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.contract.v1.UpdateContractRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.UpdateContractRequest.displayName = 'proto.swift.contract.v1.UpdateContractRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.UpdateContractResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.contract.v1.UpdateContractResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.UpdateContractResponse.displayName = 'proto.swift.contract.v1.UpdateContractResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.UpdateSurchargeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.contract.v1.UpdateSurchargeRequest.repeatedFields_, null);
};
goog.inherits(proto.swift.contract.v1.UpdateSurchargeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.UpdateSurchargeRequest.displayName = 'proto.swift.contract.v1.UpdateSurchargeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.UpdateSurchargeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.contract.v1.UpdateSurchargeResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.contract.v1.UpdateSurchargeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.UpdateSurchargeResponse.displayName = 'proto.swift.contract.v1.UpdateSurchargeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.ListSurchargesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.contract.v1.ListSurchargesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.ListSurchargesRequest.displayName = 'proto.swift.contract.v1.ListSurchargesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.contract.v1.ListSurchargesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.contract.v1.ListSurchargesResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.contract.v1.ListSurchargesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.contract.v1.ListSurchargesResponse.displayName = 'proto.swift.contract.v1.ListSurchargesResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.contract.v1.Contract.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.Contract.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.Contract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.Contract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.Contract.toObject = function(includeInstance, msg) {
  var f, obj = {
contractId: jspb.Message.getFieldWithDefault(msg, 1, 0),
contractRef: jspb.Message.getFieldWithDefault(msg, 2, ""),
name: jspb.Message.getFieldWithDefault(msg, 3, ""),
branchId: jspb.Message.getFieldWithDefault(msg, 4, 0),
organizationId: jspb.Message.getFieldWithDefault(msg, 5, 0),
salesRepresentative: jspb.Message.getFieldWithDefault(msg, 6, ""),
createdBy: jspb.Message.getFieldWithDefault(msg, 7, ""),
updatedBy: jspb.Message.getFieldWithDefault(msg, 8, ""),
status: jspb.Message.getFieldWithDefault(msg, 9, 0),
currency: jspb.Message.getFieldWithDefault(msg, 10, ""),
transportMode: jspb.Message.getFieldWithDefault(msg, 11, 0),
benefitCalculation: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
lanesList: jspb.Message.toObjectList(msg.getLanesList(),
    proto.swift.contract.v1.Lane.toObject, includeInstance),
validFrom: (f = msg.getValidFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
validTo: (f = msg.getValidTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.Contract}
 */
proto.swift.contract.v1.Contract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.Contract;
  return proto.swift.contract.v1.Contract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.Contract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.Contract}
 */
proto.swift.contract.v1.Contract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContractId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractRef(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBranchId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrganizationId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalesRepresentative(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedBy(value);
      break;
    case 9:
      var value = /** @type {!proto.swift.contract.v1.Contract.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 11:
      var value = /** @type {!proto.swift.common.TransportMode} */ (reader.readEnum());
      msg.setTransportMode(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBenefitCalculation(value);
      break;
    case 13:
      var value = new proto.swift.contract.v1.Lane;
      reader.readMessage(value,proto.swift.contract.v1.Lane.deserializeBinaryFromReader);
      msg.addLanes(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setValidFrom(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setValidTo(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.Contract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.Contract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.Contract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.Contract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getContractRef();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBranchId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOrganizationId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getSalesRepresentative();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTransportMode();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getBenefitCalculation();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getLanesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.swift.contract.v1.Lane.serializeBinaryToWriter
    );
  }
  f = message.getValidFrom();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getValidTo();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.contract.v1.Contract.Status = {
  NEW: 0,
  DRAFT: 1,
  ACTIVE: 2,
  ARCHIVED: 3
};

/**
 * optional int64 contract_id = 1;
 * @return {number}
 */
proto.swift.contract.v1.Contract.prototype.getContractId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.contract.v1.Contract} returns this
 */
proto.swift.contract.v1.Contract.prototype.setContractId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string contract_ref = 2;
 * @return {string}
 */
proto.swift.contract.v1.Contract.prototype.getContractRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.contract.v1.Contract} returns this
 */
proto.swift.contract.v1.Contract.prototype.setContractRef = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.swift.contract.v1.Contract.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.contract.v1.Contract} returns this
 */
proto.swift.contract.v1.Contract.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 branch_id = 4;
 * @return {number}
 */
proto.swift.contract.v1.Contract.prototype.getBranchId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.contract.v1.Contract} returns this
 */
proto.swift.contract.v1.Contract.prototype.setBranchId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 organization_id = 5;
 * @return {number}
 */
proto.swift.contract.v1.Contract.prototype.getOrganizationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.contract.v1.Contract} returns this
 */
proto.swift.contract.v1.Contract.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string sales_representative = 6;
 * @return {string}
 */
proto.swift.contract.v1.Contract.prototype.getSalesRepresentative = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.contract.v1.Contract} returns this
 */
proto.swift.contract.v1.Contract.prototype.setSalesRepresentative = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string created_by = 7;
 * @return {string}
 */
proto.swift.contract.v1.Contract.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.contract.v1.Contract} returns this
 */
proto.swift.contract.v1.Contract.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string updated_by = 8;
 * @return {string}
 */
proto.swift.contract.v1.Contract.prototype.getUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.contract.v1.Contract} returns this
 */
proto.swift.contract.v1.Contract.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional Status status = 9;
 * @return {!proto.swift.contract.v1.Contract.Status}
 */
proto.swift.contract.v1.Contract.prototype.getStatus = function() {
  return /** @type {!proto.swift.contract.v1.Contract.Status} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.swift.contract.v1.Contract.Status} value
 * @return {!proto.swift.contract.v1.Contract} returns this
 */
proto.swift.contract.v1.Contract.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string currency = 10;
 * @return {string}
 */
proto.swift.contract.v1.Contract.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.contract.v1.Contract} returns this
 */
proto.swift.contract.v1.Contract.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional swift.common.TransportMode transport_mode = 11;
 * @return {!proto.swift.common.TransportMode}
 */
proto.swift.contract.v1.Contract.prototype.getTransportMode = function() {
  return /** @type {!proto.swift.common.TransportMode} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.swift.common.TransportMode} value
 * @return {!proto.swift.contract.v1.Contract} returns this
 */
proto.swift.contract.v1.Contract.prototype.setTransportMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional bool benefit_calculation = 12;
 * @return {boolean}
 */
proto.swift.contract.v1.Contract.prototype.getBenefitCalculation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.contract.v1.Contract} returns this
 */
proto.swift.contract.v1.Contract.prototype.setBenefitCalculation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * repeated Lane lanes = 13;
 * @return {!Array<!proto.swift.contract.v1.Lane>}
 */
proto.swift.contract.v1.Contract.prototype.getLanesList = function() {
  return /** @type{!Array<!proto.swift.contract.v1.Lane>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.contract.v1.Lane, 13));
};


/**
 * @param {!Array<!proto.swift.contract.v1.Lane>} value
 * @return {!proto.swift.contract.v1.Contract} returns this
*/
proto.swift.contract.v1.Contract.prototype.setLanesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.swift.contract.v1.Lane=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.Lane}
 */
proto.swift.contract.v1.Contract.prototype.addLanes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.swift.contract.v1.Lane, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.Contract} returns this
 */
proto.swift.contract.v1.Contract.prototype.clearLanesList = function() {
  return this.setLanesList([]);
};


/**
 * optional google.protobuf.Timestamp valid_from = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.contract.v1.Contract.prototype.getValidFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.contract.v1.Contract} returns this
*/
proto.swift.contract.v1.Contract.prototype.setValidFrom = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.Contract} returns this
 */
proto.swift.contract.v1.Contract.prototype.clearValidFrom = function() {
  return this.setValidFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.Contract.prototype.hasValidFrom = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Timestamp valid_to = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.contract.v1.Contract.prototype.getValidTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.contract.v1.Contract} returns this
*/
proto.swift.contract.v1.Contract.prototype.setValidTo = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.Contract} returns this
 */
proto.swift.contract.v1.Contract.prototype.clearValidTo = function() {
  return this.setValidTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.Contract.prototype.hasValidTo = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.contract.v1.Contract.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.contract.v1.Contract} returns this
*/
proto.swift.contract.v1.Contract.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.Contract} returns this
 */
proto.swift.contract.v1.Contract.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.Contract.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.contract.v1.Contract.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.contract.v1.Contract} returns this
*/
proto.swift.contract.v1.Contract.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.Contract} returns this
 */
proto.swift.contract.v1.Contract.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.Contract.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 17) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.contract.v1.Lane.repeatedFields_ = [14,15];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.swift.contract.v1.Lane.oneofGroups_ = [[11]];

/**
 * @enum {number}
 */
proto.swift.contract.v1.Lane.LaneTypeCase = {
  LANE_TYPE_NOT_SET: 0,
  ROAD_TYPE: 11
};

/**
 * @return {proto.swift.contract.v1.Lane.LaneTypeCase}
 */
proto.swift.contract.v1.Lane.prototype.getLaneTypeCase = function() {
  return /** @type {proto.swift.contract.v1.Lane.LaneTypeCase} */(jspb.Message.computeOneofCase(this, proto.swift.contract.v1.Lane.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.Lane.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.Lane.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.Lane} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.Lane.toObject = function(includeInstance, msg) {
  var f, obj = {
laneId: jspb.Message.getFieldWithDefault(msg, 1, 0),
minPrice: jspb.Message.getFieldWithDefault(msg, 2, 0),
maxPrice: jspb.Message.getFieldWithDefault(msg, 3, 0),
maxChargeableWeight: jspb.Message.getFieldWithDefault(msg, 4, 0),
maxWeight: jspb.Message.getFieldWithDefault(msg, 5, 0),
laneSender: (f = msg.getLaneSender()) && crm_v1_address_pb.Address.toObject(includeInstance, f),
laneReceiver: (f = msg.getLaneReceiver()) && crm_v1_address_pb.Address.toObject(includeInstance, f),
roadType: (f = msg.getRoadType()) && proto.swift.contract.v1.RoadTypes.toObject(includeInstance, f),
calculationType: jspb.Message.getFieldWithDefault(msg, 13, 0),
breakpointsList: jspb.Message.toObjectList(msg.getBreakpointsList(),
    proto.swift.contract.v1.Breakpoint.toObject, includeInstance),
configuredSurchargesList: jspb.Message.toObjectList(msg.getConfiguredSurchargesList(),
    proto.swift.contract.v1.ConfiguredSurcharge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.Lane}
 */
proto.swift.contract.v1.Lane.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.Lane;
  return proto.swift.contract.v1.Lane.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.Lane} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.Lane}
 */
proto.swift.contract.v1.Lane.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLaneId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinPrice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxPrice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxChargeableWeight(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxWeight(value);
      break;
    case 6:
      var value = new crm_v1_address_pb.Address;
      reader.readMessage(value,crm_v1_address_pb.Address.deserializeBinaryFromReader);
      msg.setLaneSender(value);
      break;
    case 7:
      var value = new crm_v1_address_pb.Address;
      reader.readMessage(value,crm_v1_address_pb.Address.deserializeBinaryFromReader);
      msg.setLaneReceiver(value);
      break;
    case 11:
      var value = new proto.swift.contract.v1.RoadTypes;
      reader.readMessage(value,proto.swift.contract.v1.RoadTypes.deserializeBinaryFromReader);
      msg.setRoadType(value);
      break;
    case 13:
      var value = /** @type {!proto.swift.contract.v1.Lane.CalculationType} */ (reader.readEnum());
      msg.setCalculationType(value);
      break;
    case 14:
      var value = new proto.swift.contract.v1.Breakpoint;
      reader.readMessage(value,proto.swift.contract.v1.Breakpoint.deserializeBinaryFromReader);
      msg.addBreakpoints(value);
      break;
    case 15:
      var value = new proto.swift.contract.v1.ConfiguredSurcharge;
      reader.readMessage(value,proto.swift.contract.v1.ConfiguredSurcharge.deserializeBinaryFromReader);
      msg.addConfiguredSurcharges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.Lane.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.Lane.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.Lane} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.Lane.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLaneId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMinPrice();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMaxPrice();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMaxChargeableWeight();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getMaxWeight();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getLaneSender();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      crm_v1_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getLaneReceiver();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      crm_v1_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getRoadType();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.swift.contract.v1.RoadTypes.serializeBinaryToWriter
    );
  }
  f = message.getCalculationType();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getBreakpointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.swift.contract.v1.Breakpoint.serializeBinaryToWriter
    );
  }
  f = message.getConfiguredSurchargesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.swift.contract.v1.ConfiguredSurcharge.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.contract.v1.Lane.CalculationType = {
  UNKNOWN: 0,
  FOR_THE_AMOUNT: 1,
  PER_1: 2,
  PER_100: 3,
  PER_1000: 4
};

/**
 * optional int64 lane_id = 1;
 * @return {number}
 */
proto.swift.contract.v1.Lane.prototype.getLaneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.contract.v1.Lane} returns this
 */
proto.swift.contract.v1.Lane.prototype.setLaneId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 min_price = 2;
 * @return {number}
 */
proto.swift.contract.v1.Lane.prototype.getMinPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.contract.v1.Lane} returns this
 */
proto.swift.contract.v1.Lane.prototype.setMinPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 max_price = 3;
 * @return {number}
 */
proto.swift.contract.v1.Lane.prototype.getMaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.contract.v1.Lane} returns this
 */
proto.swift.contract.v1.Lane.prototype.setMaxPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 max_chargeable_weight = 4;
 * @return {number}
 */
proto.swift.contract.v1.Lane.prototype.getMaxChargeableWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.contract.v1.Lane} returns this
 */
proto.swift.contract.v1.Lane.prototype.setMaxChargeableWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 max_weight = 5;
 * @return {number}
 */
proto.swift.contract.v1.Lane.prototype.getMaxWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.contract.v1.Lane} returns this
 */
proto.swift.contract.v1.Lane.prototype.setMaxWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional swift.crm.v1.Address lane_sender = 6;
 * @return {?proto.swift.crm.v1.Address}
 */
proto.swift.contract.v1.Lane.prototype.getLaneSender = function() {
  return /** @type{?proto.swift.crm.v1.Address} */ (
    jspb.Message.getWrapperField(this, crm_v1_address_pb.Address, 6));
};


/**
 * @param {?proto.swift.crm.v1.Address|undefined} value
 * @return {!proto.swift.contract.v1.Lane} returns this
*/
proto.swift.contract.v1.Lane.prototype.setLaneSender = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.Lane} returns this
 */
proto.swift.contract.v1.Lane.prototype.clearLaneSender = function() {
  return this.setLaneSender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.Lane.prototype.hasLaneSender = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional swift.crm.v1.Address lane_receiver = 7;
 * @return {?proto.swift.crm.v1.Address}
 */
proto.swift.contract.v1.Lane.prototype.getLaneReceiver = function() {
  return /** @type{?proto.swift.crm.v1.Address} */ (
    jspb.Message.getWrapperField(this, crm_v1_address_pb.Address, 7));
};


/**
 * @param {?proto.swift.crm.v1.Address|undefined} value
 * @return {!proto.swift.contract.v1.Lane} returns this
*/
proto.swift.contract.v1.Lane.prototype.setLaneReceiver = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.Lane} returns this
 */
proto.swift.contract.v1.Lane.prototype.clearLaneReceiver = function() {
  return this.setLaneReceiver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.Lane.prototype.hasLaneReceiver = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional RoadTypes road_type = 11;
 * @return {?proto.swift.contract.v1.RoadTypes}
 */
proto.swift.contract.v1.Lane.prototype.getRoadType = function() {
  return /** @type{?proto.swift.contract.v1.RoadTypes} */ (
    jspb.Message.getWrapperField(this, proto.swift.contract.v1.RoadTypes, 11));
};


/**
 * @param {?proto.swift.contract.v1.RoadTypes|undefined} value
 * @return {!proto.swift.contract.v1.Lane} returns this
*/
proto.swift.contract.v1.Lane.prototype.setRoadType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.swift.contract.v1.Lane.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.Lane} returns this
 */
proto.swift.contract.v1.Lane.prototype.clearRoadType = function() {
  return this.setRoadType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.Lane.prototype.hasRoadType = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional CalculationType calculation_type = 13;
 * @return {!proto.swift.contract.v1.Lane.CalculationType}
 */
proto.swift.contract.v1.Lane.prototype.getCalculationType = function() {
  return /** @type {!proto.swift.contract.v1.Lane.CalculationType} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.swift.contract.v1.Lane.CalculationType} value
 * @return {!proto.swift.contract.v1.Lane} returns this
 */
proto.swift.contract.v1.Lane.prototype.setCalculationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * repeated Breakpoint breakpoints = 14;
 * @return {!Array<!proto.swift.contract.v1.Breakpoint>}
 */
proto.swift.contract.v1.Lane.prototype.getBreakpointsList = function() {
  return /** @type{!Array<!proto.swift.contract.v1.Breakpoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.contract.v1.Breakpoint, 14));
};


/**
 * @param {!Array<!proto.swift.contract.v1.Breakpoint>} value
 * @return {!proto.swift.contract.v1.Lane} returns this
*/
proto.swift.contract.v1.Lane.prototype.setBreakpointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.swift.contract.v1.Breakpoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.Breakpoint}
 */
proto.swift.contract.v1.Lane.prototype.addBreakpoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.swift.contract.v1.Breakpoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.Lane} returns this
 */
proto.swift.contract.v1.Lane.prototype.clearBreakpointsList = function() {
  return this.setBreakpointsList([]);
};


/**
 * repeated ConfiguredSurcharge configured_surcharges = 15;
 * @return {!Array<!proto.swift.contract.v1.ConfiguredSurcharge>}
 */
proto.swift.contract.v1.Lane.prototype.getConfiguredSurchargesList = function() {
  return /** @type{!Array<!proto.swift.contract.v1.ConfiguredSurcharge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.contract.v1.ConfiguredSurcharge, 15));
};


/**
 * @param {!Array<!proto.swift.contract.v1.ConfiguredSurcharge>} value
 * @return {!proto.swift.contract.v1.Lane} returns this
*/
proto.swift.contract.v1.Lane.prototype.setConfiguredSurchargesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.swift.contract.v1.ConfiguredSurcharge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.ConfiguredSurcharge}
 */
proto.swift.contract.v1.Lane.prototype.addConfiguredSurcharges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.swift.contract.v1.ConfiguredSurcharge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.Lane} returns this
 */
proto.swift.contract.v1.Lane.prototype.clearConfiguredSurchargesList = function() {
  return this.setConfiguredSurchargesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.RoadTypes.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.RoadTypes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.RoadTypes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.RoadTypes.toObject = function(includeInstance, msg) {
  var f, obj = {
transportSubmode: jspb.Message.getFieldWithDefault(msg, 1, 0),
spaceUnit: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.RoadTypes}
 */
proto.swift.contract.v1.RoadTypes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.RoadTypes;
  return proto.swift.contract.v1.RoadTypes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.RoadTypes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.RoadTypes}
 */
proto.swift.contract.v1.RoadTypes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.contract.v1.RoadTypes.TransportSubmode} */ (reader.readEnum());
      msg.setTransportSubmode(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.contract.v1.RoadTypes.SpaceUnit} */ (reader.readEnum());
      msg.setSpaceUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.RoadTypes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.RoadTypes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.RoadTypes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.RoadTypes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransportSubmode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSpaceUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.contract.v1.RoadTypes.TransportSubmode = {
  UNSPECIFIED: 0,
  PART_LOAD: 1,
  FULL_TRUCK_LOAD: 2,
  SPRINTER: 3
};

/**
 * @enum {number}
 */
proto.swift.contract.v1.RoadTypes.SpaceUnit = {
  OTHER: 0,
  CBM: 1,
  CW: 2,
  LDM: 3,
  PPL: 4
};

/**
 * optional TransportSubmode transport_submode = 1;
 * @return {!proto.swift.contract.v1.RoadTypes.TransportSubmode}
 */
proto.swift.contract.v1.RoadTypes.prototype.getTransportSubmode = function() {
  return /** @type {!proto.swift.contract.v1.RoadTypes.TransportSubmode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.contract.v1.RoadTypes.TransportSubmode} value
 * @return {!proto.swift.contract.v1.RoadTypes} returns this
 */
proto.swift.contract.v1.RoadTypes.prototype.setTransportSubmode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional SpaceUnit space_unit = 2;
 * @return {!proto.swift.contract.v1.RoadTypes.SpaceUnit}
 */
proto.swift.contract.v1.RoadTypes.prototype.getSpaceUnit = function() {
  return /** @type {!proto.swift.contract.v1.RoadTypes.SpaceUnit} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.contract.v1.RoadTypes.SpaceUnit} value
 * @return {!proto.swift.contract.v1.RoadTypes} returns this
 */
proto.swift.contract.v1.RoadTypes.prototype.setSpaceUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.Breakpoint.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.Breakpoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.Breakpoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.Breakpoint.toObject = function(includeInstance, msg) {
  var f, obj = {
breakpointId: jspb.Message.getFieldWithDefault(msg, 1, 0),
spaceUnitQuantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
rate: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.Breakpoint}
 */
proto.swift.contract.v1.Breakpoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.Breakpoint;
  return proto.swift.contract.v1.Breakpoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.Breakpoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.Breakpoint}
 */
proto.swift.contract.v1.Breakpoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBreakpointId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSpaceUnitQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.Breakpoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.Breakpoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.Breakpoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.Breakpoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBreakpointId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSpaceUnitQuantity();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 breakpoint_id = 1;
 * @return {number}
 */
proto.swift.contract.v1.Breakpoint.prototype.getBreakpointId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.contract.v1.Breakpoint} returns this
 */
proto.swift.contract.v1.Breakpoint.prototype.setBreakpointId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 space_unit_quantity = 2;
 * @return {number}
 */
proto.swift.contract.v1.Breakpoint.prototype.getSpaceUnitQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.contract.v1.Breakpoint} returns this
 */
proto.swift.contract.v1.Breakpoint.prototype.setSpaceUnitQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 rate = 3;
 * @return {number}
 */
proto.swift.contract.v1.Breakpoint.prototype.getRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.contract.v1.Breakpoint} returns this
 */
proto.swift.contract.v1.Breakpoint.prototype.setRate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.contract.v1.NominatedContract.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.NominatedContract.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.NominatedContract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.NominatedContract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.NominatedContract.toObject = function(includeInstance, msg) {
  var f, obj = {
contractName: jspb.Message.getFieldWithDefault(msg, 1, ""),
contractRef: jspb.Message.getFieldWithDefault(msg, 2, ""),
laneId: jspb.Message.getFieldWithDefault(msg, 3, 0),
currency: jspb.Message.getFieldWithDefault(msg, 4, ""),
configuredSurchargesList: jspb.Message.toObjectList(msg.getConfiguredSurchargesList(),
    proto.swift.contract.v1.ConfiguredSurcharge.toObject, includeInstance),
freightPrice: jspb.Message.getFieldWithDefault(msg, 6, 0),
totalPrice: jspb.Message.getFieldWithDefault(msg, 7, 0),
acceptedBy: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.NominatedContract}
 */
proto.swift.contract.v1.NominatedContract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.NominatedContract;
  return proto.swift.contract.v1.NominatedContract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.NominatedContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.NominatedContract}
 */
proto.swift.contract.v1.NominatedContract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractRef(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLaneId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 5:
      var value = new proto.swift.contract.v1.ConfiguredSurcharge;
      reader.readMessage(value,proto.swift.contract.v1.ConfiguredSurcharge.deserializeBinaryFromReader);
      msg.addConfiguredSurcharges(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFreightPrice(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalPrice(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAcceptedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.NominatedContract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.NominatedContract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.NominatedContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.NominatedContract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContractRef();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLaneId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getConfiguredSurchargesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.swift.contract.v1.ConfiguredSurcharge.serializeBinaryToWriter
    );
  }
  f = message.getFreightPrice();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getAcceptedBy();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string contract_name = 1;
 * @return {string}
 */
proto.swift.contract.v1.NominatedContract.prototype.getContractName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.contract.v1.NominatedContract} returns this
 */
proto.swift.contract.v1.NominatedContract.prototype.setContractName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string contract_ref = 2;
 * @return {string}
 */
proto.swift.contract.v1.NominatedContract.prototype.getContractRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.contract.v1.NominatedContract} returns this
 */
proto.swift.contract.v1.NominatedContract.prototype.setContractRef = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 lane_id = 3;
 * @return {number}
 */
proto.swift.contract.v1.NominatedContract.prototype.getLaneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.contract.v1.NominatedContract} returns this
 */
proto.swift.contract.v1.NominatedContract.prototype.setLaneId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.swift.contract.v1.NominatedContract.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.contract.v1.NominatedContract} returns this
 */
proto.swift.contract.v1.NominatedContract.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated ConfiguredSurcharge configured_surcharges = 5;
 * @return {!Array<!proto.swift.contract.v1.ConfiguredSurcharge>}
 */
proto.swift.contract.v1.NominatedContract.prototype.getConfiguredSurchargesList = function() {
  return /** @type{!Array<!proto.swift.contract.v1.ConfiguredSurcharge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.contract.v1.ConfiguredSurcharge, 5));
};


/**
 * @param {!Array<!proto.swift.contract.v1.ConfiguredSurcharge>} value
 * @return {!proto.swift.contract.v1.NominatedContract} returns this
*/
proto.swift.contract.v1.NominatedContract.prototype.setConfiguredSurchargesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.swift.contract.v1.ConfiguredSurcharge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.ConfiguredSurcharge}
 */
proto.swift.contract.v1.NominatedContract.prototype.addConfiguredSurcharges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.swift.contract.v1.ConfiguredSurcharge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.NominatedContract} returns this
 */
proto.swift.contract.v1.NominatedContract.prototype.clearConfiguredSurchargesList = function() {
  return this.setConfiguredSurchargesList([]);
};


/**
 * optional int64 freight_price = 6;
 * @return {number}
 */
proto.swift.contract.v1.NominatedContract.prototype.getFreightPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.contract.v1.NominatedContract} returns this
 */
proto.swift.contract.v1.NominatedContract.prototype.setFreightPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 total_price = 7;
 * @return {number}
 */
proto.swift.contract.v1.NominatedContract.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.contract.v1.NominatedContract} returns this
 */
proto.swift.contract.v1.NominatedContract.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string accepted_by = 8;
 * @return {string}
 */
proto.swift.contract.v1.NominatedContract.prototype.getAcceptedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.contract.v1.NominatedContract} returns this
 */
proto.swift.contract.v1.NominatedContract.prototype.setAcceptedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.ConfiguredSurcharge.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.ConfiguredSurcharge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.ConfiguredSurcharge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.ConfiguredSurcharge.toObject = function(includeInstance, msg) {
  var f, obj = {
configuredSurchargeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
isRequired: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
isIncluded: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
surcharge: (f = msg.getSurcharge()) && proto.swift.contract.v1.Surcharge.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.ConfiguredSurcharge}
 */
proto.swift.contract.v1.ConfiguredSurcharge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.ConfiguredSurcharge;
  return proto.swift.contract.v1.ConfiguredSurcharge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.ConfiguredSurcharge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.ConfiguredSurcharge}
 */
proto.swift.contract.v1.ConfiguredSurcharge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setConfiguredSurchargeId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRequired(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsIncluded(value);
      break;
    case 4:
      var value = new proto.swift.contract.v1.Surcharge;
      reader.readMessage(value,proto.swift.contract.v1.Surcharge.deserializeBinaryFromReader);
      msg.setSurcharge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.ConfiguredSurcharge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.ConfiguredSurcharge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.ConfiguredSurcharge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.ConfiguredSurcharge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfiguredSurchargeId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getIsRequired();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIsIncluded();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSurcharge();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.swift.contract.v1.Surcharge.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 configured_surcharge_id = 1;
 * @return {number}
 */
proto.swift.contract.v1.ConfiguredSurcharge.prototype.getConfiguredSurchargeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.contract.v1.ConfiguredSurcharge} returns this
 */
proto.swift.contract.v1.ConfiguredSurcharge.prototype.setConfiguredSurchargeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool is_required = 2;
 * @return {boolean}
 */
proto.swift.contract.v1.ConfiguredSurcharge.prototype.getIsRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.contract.v1.ConfiguredSurcharge} returns this
 */
proto.swift.contract.v1.ConfiguredSurcharge.prototype.setIsRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool is_included = 3;
 * @return {boolean}
 */
proto.swift.contract.v1.ConfiguredSurcharge.prototype.getIsIncluded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.contract.v1.ConfiguredSurcharge} returns this
 */
proto.swift.contract.v1.ConfiguredSurcharge.prototype.setIsIncluded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional Surcharge surcharge = 4;
 * @return {?proto.swift.contract.v1.Surcharge}
 */
proto.swift.contract.v1.ConfiguredSurcharge.prototype.getSurcharge = function() {
  return /** @type{?proto.swift.contract.v1.Surcharge} */ (
    jspb.Message.getWrapperField(this, proto.swift.contract.v1.Surcharge, 4));
};


/**
 * @param {?proto.swift.contract.v1.Surcharge|undefined} value
 * @return {!proto.swift.contract.v1.ConfiguredSurcharge} returns this
*/
proto.swift.contract.v1.ConfiguredSurcharge.prototype.setSurcharge = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.ConfiguredSurcharge} returns this
 */
proto.swift.contract.v1.ConfiguredSurcharge.prototype.clearSurcharge = function() {
  return this.setSurcharge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.ConfiguredSurcharge.prototype.hasSurcharge = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.Surcharge.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.Surcharge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.Surcharge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.Surcharge.toObject = function(includeInstance, msg) {
  var f, obj = {
surchargeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
organizationId: jspb.Message.getFieldWithDefault(msg, 2, 0),
surchargeType: jspb.Message.getFieldWithDefault(msg, 3, 0),
rate: jspb.Message.getFieldWithDefault(msg, 4, 0),
rateType: jspb.Message.getFieldWithDefault(msg, 5, 0),
currency: jspb.Message.getFieldWithDefault(msg, 6, ""),
category: jspb.Message.getFieldWithDefault(msg, 7, 0),
isActive: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.Surcharge}
 */
proto.swift.contract.v1.Surcharge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.Surcharge;
  return proto.swift.contract.v1.Surcharge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.Surcharge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.Surcharge}
 */
proto.swift.contract.v1.Surcharge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSurchargeId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrganizationId(value);
      break;
    case 3:
      var value = /** @type {!proto.swift.common.SurchargeType} */ (reader.readEnum());
      msg.setSurchargeType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRate(value);
      break;
    case 5:
      var value = /** @type {!proto.swift.contract.v1.Surcharge.RateType} */ (reader.readEnum());
      msg.setRateType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 7:
      var value = /** @type {!proto.swift.contract.v1.Surcharge.Category} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.Surcharge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.Surcharge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.Surcharge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.Surcharge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSurchargeId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOrganizationId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSurchargeType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getRate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getRateType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.contract.v1.Surcharge.RateType = {
  FIXED: 0,
  PERCENTAGE: 1
};

/**
 * @enum {number}
 */
proto.swift.contract.v1.Surcharge.Category = {
  CATEGORY_UNKNOWN: 0,
  REQUIREMENT: 1,
  FEE: 2
};

/**
 * optional int64 surcharge_id = 1;
 * @return {number}
 */
proto.swift.contract.v1.Surcharge.prototype.getSurchargeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.contract.v1.Surcharge} returns this
 */
proto.swift.contract.v1.Surcharge.prototype.setSurchargeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 organization_id = 2;
 * @return {number}
 */
proto.swift.contract.v1.Surcharge.prototype.getOrganizationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.contract.v1.Surcharge} returns this
 */
proto.swift.contract.v1.Surcharge.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional swift.common.SurchargeType surcharge_type = 3;
 * @return {!proto.swift.common.SurchargeType}
 */
proto.swift.contract.v1.Surcharge.prototype.getSurchargeType = function() {
  return /** @type {!proto.swift.common.SurchargeType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.swift.common.SurchargeType} value
 * @return {!proto.swift.contract.v1.Surcharge} returns this
 */
proto.swift.contract.v1.Surcharge.prototype.setSurchargeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 rate = 4;
 * @return {number}
 */
proto.swift.contract.v1.Surcharge.prototype.getRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.contract.v1.Surcharge} returns this
 */
proto.swift.contract.v1.Surcharge.prototype.setRate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional RateType rate_type = 5;
 * @return {!proto.swift.contract.v1.Surcharge.RateType}
 */
proto.swift.contract.v1.Surcharge.prototype.getRateType = function() {
  return /** @type {!proto.swift.contract.v1.Surcharge.RateType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.swift.contract.v1.Surcharge.RateType} value
 * @return {!proto.swift.contract.v1.Surcharge} returns this
 */
proto.swift.contract.v1.Surcharge.prototype.setRateType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string currency = 6;
 * @return {string}
 */
proto.swift.contract.v1.Surcharge.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.contract.v1.Surcharge} returns this
 */
proto.swift.contract.v1.Surcharge.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Category category = 7;
 * @return {!proto.swift.contract.v1.Surcharge.Category}
 */
proto.swift.contract.v1.Surcharge.prototype.getCategory = function() {
  return /** @type {!proto.swift.contract.v1.Surcharge.Category} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.swift.contract.v1.Surcharge.Category} value
 * @return {!proto.swift.contract.v1.Surcharge} returns this
 */
proto.swift.contract.v1.Surcharge.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool is_active = 8;
 * @return {boolean}
 */
proto.swift.contract.v1.Surcharge.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.contract.v1.Surcharge} returns this
 */
proto.swift.contract.v1.Surcharge.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.contract.v1.Surcharge.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.contract.v1.Surcharge} returns this
*/
proto.swift.contract.v1.Surcharge.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.Surcharge} returns this
 */
proto.swift.contract.v1.Surcharge.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.Surcharge.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.contract.v1.ContractFilter.repeatedFields_ = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.ContractFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.ContractFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.ContractFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.ContractFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
contractIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
contractRefList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
salesRepresentativeList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
branchIdList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
organizationIdList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
statusList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
transportModeList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
fromLaneIdList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
toLaneIdList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
fromPostalCodeList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
toPostalCodeList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
fromCityList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
toCityList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
fromCountryIdList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
toCountryIdList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
benefitCalculation: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
dateList: jspb.Message.toObjectList(msg.getDateList(),
    proto.swift.contract.v1.DateFilter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.ContractFilter}
 */
proto.swift.contract.v1.ContractFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.ContractFilter;
  return proto.swift.contract.v1.ContractFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.ContractFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.ContractFilter}
 */
proto.swift.contract.v1.ContractFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addContractId(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addContractRef(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addSalesRepresentative(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBranchId(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrganizationId(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<!proto.swift.contract.v1.Contract.Status>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatus(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<!proto.swift.common.TransportMode>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTransportMode(values[i]);
      }
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFromLaneId(values[i]);
      }
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addToLaneId(values[i]);
      }
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addFromPostalCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addToPostalCode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addFromCity(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addToCity(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addFromCountryId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addToCountryId(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBenefitCalculation(value);
      break;
    case 17:
      var value = new proto.swift.contract.v1.DateFilter;
      reader.readMessage(value,proto.swift.contract.v1.DateFilter.deserializeBinaryFromReader);
      msg.addDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.ContractFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.ContractFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.ContractFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.ContractFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getContractRefList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getSalesRepresentativeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getBranchIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
  f = message.getOrganizationIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
  f = message.getTransportModeList();
  if (f.length > 0) {
    writer.writePackedEnum(
      7,
      f
    );
  }
  f = message.getFromLaneIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      8,
      f
    );
  }
  f = message.getToLaneIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      9,
      f
    );
  }
  f = message.getFromPostalCodeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getToPostalCodeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getFromCityList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getToCityList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
  f = message.getFromCountryIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getToCountryIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
  f = message.getBenefitCalculation();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getDateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.swift.contract.v1.DateFilter.serializeBinaryToWriter
    );
  }
};


/**
 * repeated int64 contract_id = 1;
 * @return {!Array<number>}
 */
proto.swift.contract.v1.ContractFilter.prototype.getContractIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.setContractIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.addContractId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.clearContractIdList = function() {
  return this.setContractIdList([]);
};


/**
 * repeated string contract_ref = 2;
 * @return {!Array<string>}
 */
proto.swift.contract.v1.ContractFilter.prototype.getContractRefList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.setContractRefList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.addContractRef = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.clearContractRefList = function() {
  return this.setContractRefList([]);
};


/**
 * repeated string sales_representative = 3;
 * @return {!Array<string>}
 */
proto.swift.contract.v1.ContractFilter.prototype.getSalesRepresentativeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.setSalesRepresentativeList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.addSalesRepresentative = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.clearSalesRepresentativeList = function() {
  return this.setSalesRepresentativeList([]);
};


/**
 * repeated int64 branch_id = 4;
 * @return {!Array<number>}
 */
proto.swift.contract.v1.ContractFilter.prototype.getBranchIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.setBranchIdList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.addBranchId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.clearBranchIdList = function() {
  return this.setBranchIdList([]);
};


/**
 * repeated int64 organization_id = 5;
 * @return {!Array<number>}
 */
proto.swift.contract.v1.ContractFilter.prototype.getOrganizationIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.setOrganizationIdList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.addOrganizationId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.clearOrganizationIdList = function() {
  return this.setOrganizationIdList([]);
};


/**
 * repeated Contract.Status status = 6;
 * @return {!Array<!proto.swift.contract.v1.Contract.Status>}
 */
proto.swift.contract.v1.ContractFilter.prototype.getStatusList = function() {
  return /** @type {!Array<!proto.swift.contract.v1.Contract.Status>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.swift.contract.v1.Contract.Status>} value
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.setStatusList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.swift.contract.v1.Contract.Status} value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.addStatus = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};


/**
 * repeated swift.common.TransportMode transport_mode = 7;
 * @return {!Array<!proto.swift.common.TransportMode>}
 */
proto.swift.contract.v1.ContractFilter.prototype.getTransportModeList = function() {
  return /** @type {!Array<!proto.swift.common.TransportMode>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<!proto.swift.common.TransportMode>} value
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.setTransportModeList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {!proto.swift.common.TransportMode} value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.addTransportMode = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.clearTransportModeList = function() {
  return this.setTransportModeList([]);
};


/**
 * repeated int64 from_lane_id = 8;
 * @return {!Array<number>}
 */
proto.swift.contract.v1.ContractFilter.prototype.getFromLaneIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.setFromLaneIdList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.addFromLaneId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.clearFromLaneIdList = function() {
  return this.setFromLaneIdList([]);
};


/**
 * repeated int64 to_lane_id = 9;
 * @return {!Array<number>}
 */
proto.swift.contract.v1.ContractFilter.prototype.getToLaneIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.setToLaneIdList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.addToLaneId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.clearToLaneIdList = function() {
  return this.setToLaneIdList([]);
};


/**
 * repeated string from_postal_code = 10;
 * @return {!Array<string>}
 */
proto.swift.contract.v1.ContractFilter.prototype.getFromPostalCodeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.setFromPostalCodeList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.addFromPostalCode = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.clearFromPostalCodeList = function() {
  return this.setFromPostalCodeList([]);
};


/**
 * repeated string to_postal_code = 11;
 * @return {!Array<string>}
 */
proto.swift.contract.v1.ContractFilter.prototype.getToPostalCodeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.setToPostalCodeList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.addToPostalCode = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.clearToPostalCodeList = function() {
  return this.setToPostalCodeList([]);
};


/**
 * repeated string from_city = 12;
 * @return {!Array<string>}
 */
proto.swift.contract.v1.ContractFilter.prototype.getFromCityList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.setFromCityList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.addFromCity = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.clearFromCityList = function() {
  return this.setFromCityList([]);
};


/**
 * repeated string to_city = 13;
 * @return {!Array<string>}
 */
proto.swift.contract.v1.ContractFilter.prototype.getToCityList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.setToCityList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.addToCity = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.clearToCityList = function() {
  return this.setToCityList([]);
};


/**
 * repeated string from_country_id = 14;
 * @return {!Array<string>}
 */
proto.swift.contract.v1.ContractFilter.prototype.getFromCountryIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.setFromCountryIdList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.addFromCountryId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.clearFromCountryIdList = function() {
  return this.setFromCountryIdList([]);
};


/**
 * repeated string to_country_id = 15;
 * @return {!Array<string>}
 */
proto.swift.contract.v1.ContractFilter.prototype.getToCountryIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.setToCountryIdList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.addToCountryId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.clearToCountryIdList = function() {
  return this.setToCountryIdList([]);
};


/**
 * optional bool benefit_calculation = 16;
 * @return {boolean}
 */
proto.swift.contract.v1.ContractFilter.prototype.getBenefitCalculation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.setBenefitCalculation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * repeated DateFilter date = 17;
 * @return {!Array<!proto.swift.contract.v1.DateFilter>}
 */
proto.swift.contract.v1.ContractFilter.prototype.getDateList = function() {
  return /** @type{!Array<!proto.swift.contract.v1.DateFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.contract.v1.DateFilter, 17));
};


/**
 * @param {!Array<!proto.swift.contract.v1.DateFilter>} value
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
*/
proto.swift.contract.v1.ContractFilter.prototype.setDateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.swift.contract.v1.DateFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.DateFilter}
 */
proto.swift.contract.v1.ContractFilter.prototype.addDate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.swift.contract.v1.DateFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.ContractFilter} returns this
 */
proto.swift.contract.v1.ContractFilter.prototype.clearDateList = function() {
  return this.setDateList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.swift.contract.v1.DateFilter.oneofGroups_ = [[3,4]];

/**
 * @enum {number}
 */
proto.swift.contract.v1.DateFilter.ValueCase = {
  VALUE_NOT_SET: 0,
  RELATIVE: 3,
  ABSOLUTE: 4
};

/**
 * @return {proto.swift.contract.v1.DateFilter.ValueCase}
 */
proto.swift.contract.v1.DateFilter.prototype.getValueCase = function() {
  return /** @type {proto.swift.contract.v1.DateFilter.ValueCase} */(jspb.Message.computeOneofCase(this, proto.swift.contract.v1.DateFilter.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.DateFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.DateFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.DateFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.DateFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
field: jspb.Message.getFieldWithDefault(msg, 1, 0),
operator: jspb.Message.getFieldWithDefault(msg, 2, 0),
relative: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
absolute: (f = msg.getAbsolute()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.DateFilter}
 */
proto.swift.contract.v1.DateFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.DateFilter;
  return proto.swift.contract.v1.DateFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.DateFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.DateFilter}
 */
proto.swift.contract.v1.DateFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.contract.v1.DateFilter.Field} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.common.FilterOperator} */ (reader.readEnum());
      msg.setOperator(value);
      break;
    case 3:
      var value = /** @type {!proto.swift.contract.v1.DateFilter.Relative} */ (reader.readEnum());
      msg.setRelative(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAbsolute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.DateFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.DateFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.DateFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.DateFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOperator();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {!proto.swift.contract.v1.DateFilter.Relative} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAbsolute();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.contract.v1.DateFilter.Field = {
  UNKNOWN: 0,
  CREATED_AT: 1,
  UPDATED_AT: 2,
  VALID_FROM: 3,
  VALID_TO: 4
};

/**
 * @enum {number}
 */
proto.swift.contract.v1.DateFilter.Relative = {
  NOW: 0,
  CURRENT_DATE: 1
};

/**
 * optional Field field = 1;
 * @return {!proto.swift.contract.v1.DateFilter.Field}
 */
proto.swift.contract.v1.DateFilter.prototype.getField = function() {
  return /** @type {!proto.swift.contract.v1.DateFilter.Field} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.contract.v1.DateFilter.Field} value
 * @return {!proto.swift.contract.v1.DateFilter} returns this
 */
proto.swift.contract.v1.DateFilter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional swift.common.FilterOperator operator = 2;
 * @return {!proto.swift.common.FilterOperator}
 */
proto.swift.contract.v1.DateFilter.prototype.getOperator = function() {
  return /** @type {!proto.swift.common.FilterOperator} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.common.FilterOperator} value
 * @return {!proto.swift.contract.v1.DateFilter} returns this
 */
proto.swift.contract.v1.DateFilter.prototype.setOperator = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Relative relative = 3;
 * @return {!proto.swift.contract.v1.DateFilter.Relative}
 */
proto.swift.contract.v1.DateFilter.prototype.getRelative = function() {
  return /** @type {!proto.swift.contract.v1.DateFilter.Relative} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.swift.contract.v1.DateFilter.Relative} value
 * @return {!proto.swift.contract.v1.DateFilter} returns this
 */
proto.swift.contract.v1.DateFilter.prototype.setRelative = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.swift.contract.v1.DateFilter.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.swift.contract.v1.DateFilter} returns this
 */
proto.swift.contract.v1.DateFilter.prototype.clearRelative = function() {
  return jspb.Message.setOneofField(this, 3, proto.swift.contract.v1.DateFilter.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.DateFilter.prototype.hasRelative = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp absolute = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.contract.v1.DateFilter.prototype.getAbsolute = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.contract.v1.DateFilter} returns this
*/
proto.swift.contract.v1.DateFilter.prototype.setAbsolute = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.swift.contract.v1.DateFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.DateFilter} returns this
 */
proto.swift.contract.v1.DateFilter.prototype.clearAbsolute = function() {
  return this.setAbsolute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.DateFilter.prototype.hasAbsolute = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.ContractSorting.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.ContractSorting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.ContractSorting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.ContractSorting.toObject = function(includeInstance, msg) {
  var f, obj = {
field: jspb.Message.getFieldWithDefault(msg, 1, 0),
ordering: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.ContractSorting}
 */
proto.swift.contract.v1.ContractSorting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.ContractSorting;
  return proto.swift.contract.v1.ContractSorting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.ContractSorting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.ContractSorting}
 */
proto.swift.contract.v1.ContractSorting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.contract.v1.ContractSorting.Field} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.contract.v1.ContractSorting.Ordering} */ (reader.readEnum());
      msg.setOrdering(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.ContractSorting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.ContractSorting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.ContractSorting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.ContractSorting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOrdering();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.contract.v1.ContractSorting.Ordering = {
  UNKNOWN: 0,
  ASC: 1,
  DESC: 2
};

/**
 * @enum {number}
 */
proto.swift.contract.v1.ContractSorting.Field = {
  CONTRACT_REF: 0,
  STATUS: 1,
  TRANSPORT_MODE: 2,
  SALES_REPRESENTATIVE: 3,
  CREATED_AT: 4,
  UPDATED_AT: 5,
  VALID_FROM: 6,
  VALID_TO: 7
};

/**
 * optional Field field = 1;
 * @return {!proto.swift.contract.v1.ContractSorting.Field}
 */
proto.swift.contract.v1.ContractSorting.prototype.getField = function() {
  return /** @type {!proto.swift.contract.v1.ContractSorting.Field} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.contract.v1.ContractSorting.Field} value
 * @return {!proto.swift.contract.v1.ContractSorting} returns this
 */
proto.swift.contract.v1.ContractSorting.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Ordering ordering = 2;
 * @return {!proto.swift.contract.v1.ContractSorting.Ordering}
 */
proto.swift.contract.v1.ContractSorting.prototype.getOrdering = function() {
  return /** @type {!proto.swift.contract.v1.ContractSorting.Ordering} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.contract.v1.ContractSorting.Ordering} value
 * @return {!proto.swift.contract.v1.ContractSorting} returns this
 */
proto.swift.contract.v1.ContractSorting.prototype.setOrdering = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.contract.v1.SurchargeFilter.repeatedFields_ = [2,3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.SurchargeFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.SurchargeFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.SurchargeFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.SurchargeFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
isActive: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
organizationIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
surchargeTypeList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
categoryList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
currencyList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
rateTypeList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.SurchargeFilter}
 */
proto.swift.contract.v1.SurchargeFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.SurchargeFilter;
  return proto.swift.contract.v1.SurchargeFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.SurchargeFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.SurchargeFilter}
 */
proto.swift.contract.v1.SurchargeFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrganizationId(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<!proto.swift.common.SurchargeType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSurchargeType(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<!proto.swift.contract.v1.Surcharge.Category>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCategory(values[i]);
      }
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addCurrency(value);
      break;
    case 6:
      var values = /** @type {!Array<!proto.swift.contract.v1.Surcharge.RateType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRateType(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.SurchargeFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.SurchargeFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.SurchargeFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.SurchargeFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getOrganizationIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getSurchargeTypeList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getCategoryList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getCurrencyList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getRateTypeList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
};


/**
 * optional bool is_active = 1;
 * @return {boolean}
 */
proto.swift.contract.v1.SurchargeFilter.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.contract.v1.SurchargeFilter} returns this
 */
proto.swift.contract.v1.SurchargeFilter.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated int64 organization_id = 2;
 * @return {!Array<number>}
 */
proto.swift.contract.v1.SurchargeFilter.prototype.getOrganizationIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.contract.v1.SurchargeFilter} returns this
 */
proto.swift.contract.v1.SurchargeFilter.prototype.setOrganizationIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.SurchargeFilter} returns this
 */
proto.swift.contract.v1.SurchargeFilter.prototype.addOrganizationId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.SurchargeFilter} returns this
 */
proto.swift.contract.v1.SurchargeFilter.prototype.clearOrganizationIdList = function() {
  return this.setOrganizationIdList([]);
};


/**
 * repeated swift.common.SurchargeType surcharge_type = 3;
 * @return {!Array<!proto.swift.common.SurchargeType>}
 */
proto.swift.contract.v1.SurchargeFilter.prototype.getSurchargeTypeList = function() {
  return /** @type {!Array<!proto.swift.common.SurchargeType>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.swift.common.SurchargeType>} value
 * @return {!proto.swift.contract.v1.SurchargeFilter} returns this
 */
proto.swift.contract.v1.SurchargeFilter.prototype.setSurchargeTypeList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.swift.common.SurchargeType} value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.SurchargeFilter} returns this
 */
proto.swift.contract.v1.SurchargeFilter.prototype.addSurchargeType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.SurchargeFilter} returns this
 */
proto.swift.contract.v1.SurchargeFilter.prototype.clearSurchargeTypeList = function() {
  return this.setSurchargeTypeList([]);
};


/**
 * repeated Surcharge.Category category = 4;
 * @return {!Array<!proto.swift.contract.v1.Surcharge.Category>}
 */
proto.swift.contract.v1.SurchargeFilter.prototype.getCategoryList = function() {
  return /** @type {!Array<!proto.swift.contract.v1.Surcharge.Category>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.swift.contract.v1.Surcharge.Category>} value
 * @return {!proto.swift.contract.v1.SurchargeFilter} returns this
 */
proto.swift.contract.v1.SurchargeFilter.prototype.setCategoryList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.swift.contract.v1.Surcharge.Category} value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.SurchargeFilter} returns this
 */
proto.swift.contract.v1.SurchargeFilter.prototype.addCategory = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.SurchargeFilter} returns this
 */
proto.swift.contract.v1.SurchargeFilter.prototype.clearCategoryList = function() {
  return this.setCategoryList([]);
};


/**
 * repeated string currency = 5;
 * @return {!Array<string>}
 */
proto.swift.contract.v1.SurchargeFilter.prototype.getCurrencyList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.contract.v1.SurchargeFilter} returns this
 */
proto.swift.contract.v1.SurchargeFilter.prototype.setCurrencyList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.SurchargeFilter} returns this
 */
proto.swift.contract.v1.SurchargeFilter.prototype.addCurrency = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.SurchargeFilter} returns this
 */
proto.swift.contract.v1.SurchargeFilter.prototype.clearCurrencyList = function() {
  return this.setCurrencyList([]);
};


/**
 * repeated Surcharge.RateType rate_type = 6;
 * @return {!Array<!proto.swift.contract.v1.Surcharge.RateType>}
 */
proto.swift.contract.v1.SurchargeFilter.prototype.getRateTypeList = function() {
  return /** @type {!Array<!proto.swift.contract.v1.Surcharge.RateType>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.swift.contract.v1.Surcharge.RateType>} value
 * @return {!proto.swift.contract.v1.SurchargeFilter} returns this
 */
proto.swift.contract.v1.SurchargeFilter.prototype.setRateTypeList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.swift.contract.v1.Surcharge.RateType} value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.SurchargeFilter} returns this
 */
proto.swift.contract.v1.SurchargeFilter.prototype.addRateType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.SurchargeFilter} returns this
 */
proto.swift.contract.v1.SurchargeFilter.prototype.clearRateTypeList = function() {
  return this.setRateTypeList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.SurchargeSorting.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.SurchargeSorting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.SurchargeSorting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.SurchargeSorting.toObject = function(includeInstance, msg) {
  var f, obj = {
field: jspb.Message.getFieldWithDefault(msg, 1, 0),
ordering: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.SurchargeSorting}
 */
proto.swift.contract.v1.SurchargeSorting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.SurchargeSorting;
  return proto.swift.contract.v1.SurchargeSorting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.SurchargeSorting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.SurchargeSorting}
 */
proto.swift.contract.v1.SurchargeSorting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.contract.v1.SurchargeSorting.Field} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.contract.v1.SurchargeSorting.Ordering} */ (reader.readEnum());
      msg.setOrdering(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.SurchargeSorting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.SurchargeSorting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.SurchargeSorting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.SurchargeSorting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOrdering();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.contract.v1.SurchargeSorting.Ordering = {
  UNKNOWN: 0,
  ASC: 1,
  DESC: 2
};

/**
 * @enum {number}
 */
proto.swift.contract.v1.SurchargeSorting.Field = {
  SURCHARGE_TYPE: 0,
  IS_ACTIVE: 1,
  ORGANIZATION_ID: 2,
  CATEGORY: 3,
  CURRENCY: 4
};

/**
 * optional Field field = 1;
 * @return {!proto.swift.contract.v1.SurchargeSorting.Field}
 */
proto.swift.contract.v1.SurchargeSorting.prototype.getField = function() {
  return /** @type {!proto.swift.contract.v1.SurchargeSorting.Field} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.contract.v1.SurchargeSorting.Field} value
 * @return {!proto.swift.contract.v1.SurchargeSorting} returns this
 */
proto.swift.contract.v1.SurchargeSorting.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Ordering ordering = 2;
 * @return {!proto.swift.contract.v1.SurchargeSorting.Ordering}
 */
proto.swift.contract.v1.SurchargeSorting.prototype.getOrdering = function() {
  return /** @type {!proto.swift.contract.v1.SurchargeSorting.Ordering} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.contract.v1.SurchargeSorting.Ordering} value
 * @return {!proto.swift.contract.v1.SurchargeSorting} returns this
 */
proto.swift.contract.v1.SurchargeSorting.prototype.setOrdering = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.ListContractsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.ListContractsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.ListContractsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.ListContractsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
pagination: (f = msg.getPagination()) && common_query_pb.Pagination.toObject(includeInstance, f),
filter: (f = msg.getFilter()) && proto.swift.contract.v1.ContractFilter.toObject(includeInstance, f),
sorting: (f = msg.getSorting()) && proto.swift.contract.v1.ContractSorting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.ListContractsRequest}
 */
proto.swift.contract.v1.ListContractsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.ListContractsRequest;
  return proto.swift.contract.v1.ListContractsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.ListContractsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.ListContractsRequest}
 */
proto.swift.contract.v1.ListContractsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_query_pb.Pagination;
      reader.readMessage(value,common_query_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = new proto.swift.contract.v1.ContractFilter;
      reader.readMessage(value,proto.swift.contract.v1.ContractFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new proto.swift.contract.v1.ContractSorting;
      reader.readMessage(value,proto.swift.contract.v1.ContractSorting.deserializeBinaryFromReader);
      msg.setSorting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.ListContractsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.ListContractsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.ListContractsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.ListContractsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_query_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.contract.v1.ContractFilter.serializeBinaryToWriter
    );
  }
  f = message.getSorting();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.swift.contract.v1.ContractSorting.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.common.Pagination pagination = 1;
 * @return {?proto.swift.common.Pagination}
 */
proto.swift.contract.v1.ListContractsRequest.prototype.getPagination = function() {
  return /** @type{?proto.swift.common.Pagination} */ (
    jspb.Message.getWrapperField(this, common_query_pb.Pagination, 1));
};


/**
 * @param {?proto.swift.common.Pagination|undefined} value
 * @return {!proto.swift.contract.v1.ListContractsRequest} returns this
*/
proto.swift.contract.v1.ListContractsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.ListContractsRequest} returns this
 */
proto.swift.contract.v1.ListContractsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.ListContractsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ContractFilter filter = 2;
 * @return {?proto.swift.contract.v1.ContractFilter}
 */
proto.swift.contract.v1.ListContractsRequest.prototype.getFilter = function() {
  return /** @type{?proto.swift.contract.v1.ContractFilter} */ (
    jspb.Message.getWrapperField(this, proto.swift.contract.v1.ContractFilter, 2));
};


/**
 * @param {?proto.swift.contract.v1.ContractFilter|undefined} value
 * @return {!proto.swift.contract.v1.ListContractsRequest} returns this
*/
proto.swift.contract.v1.ListContractsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.ListContractsRequest} returns this
 */
proto.swift.contract.v1.ListContractsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.ListContractsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ContractSorting sorting = 3;
 * @return {?proto.swift.contract.v1.ContractSorting}
 */
proto.swift.contract.v1.ListContractsRequest.prototype.getSorting = function() {
  return /** @type{?proto.swift.contract.v1.ContractSorting} */ (
    jspb.Message.getWrapperField(this, proto.swift.contract.v1.ContractSorting, 3));
};


/**
 * @param {?proto.swift.contract.v1.ContractSorting|undefined} value
 * @return {!proto.swift.contract.v1.ListContractsRequest} returns this
*/
proto.swift.contract.v1.ListContractsRequest.prototype.setSorting = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.ListContractsRequest} returns this
 */
proto.swift.contract.v1.ListContractsRequest.prototype.clearSorting = function() {
  return this.setSorting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.ListContractsRequest.prototype.hasSorting = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.contract.v1.ListContractsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.ListContractsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.ListContractsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.ListContractsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.ListContractsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
contractsList: jspb.Message.toObjectList(msg.getContractsList(),
    proto.swift.contract.v1.Contract.toObject, includeInstance),
count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.ListContractsResponse}
 */
proto.swift.contract.v1.ListContractsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.ListContractsResponse;
  return proto.swift.contract.v1.ListContractsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.ListContractsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.ListContractsResponse}
 */
proto.swift.contract.v1.ListContractsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.contract.v1.Contract;
      reader.readMessage(value,proto.swift.contract.v1.Contract.deserializeBinaryFromReader);
      msg.addContracts(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.ListContractsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.ListContractsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.ListContractsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.ListContractsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.contract.v1.Contract.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated Contract contracts = 1;
 * @return {!Array<!proto.swift.contract.v1.Contract>}
 */
proto.swift.contract.v1.ListContractsResponse.prototype.getContractsList = function() {
  return /** @type{!Array<!proto.swift.contract.v1.Contract>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.contract.v1.Contract, 1));
};


/**
 * @param {!Array<!proto.swift.contract.v1.Contract>} value
 * @return {!proto.swift.contract.v1.ListContractsResponse} returns this
*/
proto.swift.contract.v1.ListContractsResponse.prototype.setContractsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.contract.v1.Contract=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.Contract}
 */
proto.swift.contract.v1.ListContractsResponse.prototype.addContracts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.contract.v1.Contract, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.ListContractsResponse} returns this
 */
proto.swift.contract.v1.ListContractsResponse.prototype.clearContractsList = function() {
  return this.setContractsList([]);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.swift.contract.v1.ListContractsResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.contract.v1.ListContractsResponse} returns this
 */
proto.swift.contract.v1.ListContractsResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.GetContractRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.GetContractRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.GetContractRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.GetContractRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
contractId: jspb.Message.getFieldWithDefault(msg, 1, 0),
contractRef: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.GetContractRequest}
 */
proto.swift.contract.v1.GetContractRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.GetContractRequest;
  return proto.swift.contract.v1.GetContractRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.GetContractRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.GetContractRequest}
 */
proto.swift.contract.v1.GetContractRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContractId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractRef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.GetContractRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.GetContractRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.GetContractRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.GetContractRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getContractRef();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 contract_id = 1;
 * @return {number}
 */
proto.swift.contract.v1.GetContractRequest.prototype.getContractId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.contract.v1.GetContractRequest} returns this
 */
proto.swift.contract.v1.GetContractRequest.prototype.setContractId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string contract_ref = 2;
 * @return {string}
 */
proto.swift.contract.v1.GetContractRequest.prototype.getContractRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.contract.v1.GetContractRequest} returns this
 */
proto.swift.contract.v1.GetContractRequest.prototype.setContractRef = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.GetContractResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.GetContractResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.GetContractResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.GetContractResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
contract: (f = msg.getContract()) && proto.swift.contract.v1.Contract.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.GetContractResponse}
 */
proto.swift.contract.v1.GetContractResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.GetContractResponse;
  return proto.swift.contract.v1.GetContractResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.GetContractResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.GetContractResponse}
 */
proto.swift.contract.v1.GetContractResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.contract.v1.Contract;
      reader.readMessage(value,proto.swift.contract.v1.Contract.deserializeBinaryFromReader);
      msg.setContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.GetContractResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.GetContractResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.GetContractResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.GetContractResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContract();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.contract.v1.Contract.serializeBinaryToWriter
    );
  }
};


/**
 * optional Contract contract = 1;
 * @return {?proto.swift.contract.v1.Contract}
 */
proto.swift.contract.v1.GetContractResponse.prototype.getContract = function() {
  return /** @type{?proto.swift.contract.v1.Contract} */ (
    jspb.Message.getWrapperField(this, proto.swift.contract.v1.Contract, 1));
};


/**
 * @param {?proto.swift.contract.v1.Contract|undefined} value
 * @return {!proto.swift.contract.v1.GetContractResponse} returns this
*/
proto.swift.contract.v1.GetContractResponse.prototype.setContract = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.GetContractResponse} returns this
 */
proto.swift.contract.v1.GetContractResponse.prototype.clearContract = function() {
  return this.setContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.GetContractResponse.prototype.hasContract = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.CreateContractRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.CreateContractRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.CreateContractRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.CreateContractRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
contract: (f = msg.getContract()) && proto.swift.contract.v1.Contract.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.CreateContractRequest}
 */
proto.swift.contract.v1.CreateContractRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.CreateContractRequest;
  return proto.swift.contract.v1.CreateContractRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.CreateContractRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.CreateContractRequest}
 */
proto.swift.contract.v1.CreateContractRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.contract.v1.Contract;
      reader.readMessage(value,proto.swift.contract.v1.Contract.deserializeBinaryFromReader);
      msg.setContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.CreateContractRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.CreateContractRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.CreateContractRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.CreateContractRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContract();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.contract.v1.Contract.serializeBinaryToWriter
    );
  }
};


/**
 * optional Contract contract = 1;
 * @return {?proto.swift.contract.v1.Contract}
 */
proto.swift.contract.v1.CreateContractRequest.prototype.getContract = function() {
  return /** @type{?proto.swift.contract.v1.Contract} */ (
    jspb.Message.getWrapperField(this, proto.swift.contract.v1.Contract, 1));
};


/**
 * @param {?proto.swift.contract.v1.Contract|undefined} value
 * @return {!proto.swift.contract.v1.CreateContractRequest} returns this
*/
proto.swift.contract.v1.CreateContractRequest.prototype.setContract = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.CreateContractRequest} returns this
 */
proto.swift.contract.v1.CreateContractRequest.prototype.clearContract = function() {
  return this.setContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.CreateContractRequest.prototype.hasContract = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.CreateContractResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.CreateContractResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.CreateContractResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.CreateContractResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
contract: (f = msg.getContract()) && proto.swift.contract.v1.Contract.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.CreateContractResponse}
 */
proto.swift.contract.v1.CreateContractResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.CreateContractResponse;
  return proto.swift.contract.v1.CreateContractResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.CreateContractResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.CreateContractResponse}
 */
proto.swift.contract.v1.CreateContractResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.swift.contract.v1.Contract;
      reader.readMessage(value,proto.swift.contract.v1.Contract.deserializeBinaryFromReader);
      msg.setContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.CreateContractResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.CreateContractResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.CreateContractResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.CreateContractResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContract();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.contract.v1.Contract.serializeBinaryToWriter
    );
  }
};


/**
 * optional Contract contract = 2;
 * @return {?proto.swift.contract.v1.Contract}
 */
proto.swift.contract.v1.CreateContractResponse.prototype.getContract = function() {
  return /** @type{?proto.swift.contract.v1.Contract} */ (
    jspb.Message.getWrapperField(this, proto.swift.contract.v1.Contract, 2));
};


/**
 * @param {?proto.swift.contract.v1.Contract|undefined} value
 * @return {!proto.swift.contract.v1.CreateContractResponse} returns this
*/
proto.swift.contract.v1.CreateContractResponse.prototype.setContract = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.CreateContractResponse} returns this
 */
proto.swift.contract.v1.CreateContractResponse.prototype.clearContract = function() {
  return this.setContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.CreateContractResponse.prototype.hasContract = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.CreateSurchargeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.CreateSurchargeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.CreateSurchargeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.CreateSurchargeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
surcharge: (f = msg.getSurcharge()) && proto.swift.contract.v1.Surcharge.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.CreateSurchargeRequest}
 */
proto.swift.contract.v1.CreateSurchargeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.CreateSurchargeRequest;
  return proto.swift.contract.v1.CreateSurchargeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.CreateSurchargeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.CreateSurchargeRequest}
 */
proto.swift.contract.v1.CreateSurchargeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.contract.v1.Surcharge;
      reader.readMessage(value,proto.swift.contract.v1.Surcharge.deserializeBinaryFromReader);
      msg.setSurcharge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.CreateSurchargeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.CreateSurchargeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.CreateSurchargeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.CreateSurchargeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSurcharge();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.contract.v1.Surcharge.serializeBinaryToWriter
    );
  }
};


/**
 * optional Surcharge surcharge = 1;
 * @return {?proto.swift.contract.v1.Surcharge}
 */
proto.swift.contract.v1.CreateSurchargeRequest.prototype.getSurcharge = function() {
  return /** @type{?proto.swift.contract.v1.Surcharge} */ (
    jspb.Message.getWrapperField(this, proto.swift.contract.v1.Surcharge, 1));
};


/**
 * @param {?proto.swift.contract.v1.Surcharge|undefined} value
 * @return {!proto.swift.contract.v1.CreateSurchargeRequest} returns this
*/
proto.swift.contract.v1.CreateSurchargeRequest.prototype.setSurcharge = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.CreateSurchargeRequest} returns this
 */
proto.swift.contract.v1.CreateSurchargeRequest.prototype.clearSurcharge = function() {
  return this.setSurcharge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.CreateSurchargeRequest.prototype.hasSurcharge = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.CreateSurchargeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.CreateSurchargeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.CreateSurchargeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.CreateSurchargeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
surcharge: (f = msg.getSurcharge()) && proto.swift.contract.v1.Surcharge.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.CreateSurchargeResponse}
 */
proto.swift.contract.v1.CreateSurchargeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.CreateSurchargeResponse;
  return proto.swift.contract.v1.CreateSurchargeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.CreateSurchargeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.CreateSurchargeResponse}
 */
proto.swift.contract.v1.CreateSurchargeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.contract.v1.Surcharge;
      reader.readMessage(value,proto.swift.contract.v1.Surcharge.deserializeBinaryFromReader);
      msg.setSurcharge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.CreateSurchargeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.CreateSurchargeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.CreateSurchargeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.CreateSurchargeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSurcharge();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.contract.v1.Surcharge.serializeBinaryToWriter
    );
  }
};


/**
 * optional Surcharge surcharge = 1;
 * @return {?proto.swift.contract.v1.Surcharge}
 */
proto.swift.contract.v1.CreateSurchargeResponse.prototype.getSurcharge = function() {
  return /** @type{?proto.swift.contract.v1.Surcharge} */ (
    jspb.Message.getWrapperField(this, proto.swift.contract.v1.Surcharge, 1));
};


/**
 * @param {?proto.swift.contract.v1.Surcharge|undefined} value
 * @return {!proto.swift.contract.v1.CreateSurchargeResponse} returns this
*/
proto.swift.contract.v1.CreateSurchargeResponse.prototype.setSurcharge = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.CreateSurchargeResponse} returns this
 */
proto.swift.contract.v1.CreateSurchargeResponse.prototype.clearSurcharge = function() {
  return this.setSurcharge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.CreateSurchargeResponse.prototype.hasSurcharge = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.UpdateContractRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.UpdateContractRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.UpdateContractRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.UpdateContractRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
contract: (f = msg.getContract()) && proto.swift.contract.v1.Contract.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.UpdateContractRequest}
 */
proto.swift.contract.v1.UpdateContractRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.UpdateContractRequest;
  return proto.swift.contract.v1.UpdateContractRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.UpdateContractRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.UpdateContractRequest}
 */
proto.swift.contract.v1.UpdateContractRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.contract.v1.Contract;
      reader.readMessage(value,proto.swift.contract.v1.Contract.deserializeBinaryFromReader);
      msg.setContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.UpdateContractRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.UpdateContractRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.UpdateContractRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.UpdateContractRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContract();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.contract.v1.Contract.serializeBinaryToWriter
    );
  }
};


/**
 * optional Contract contract = 1;
 * @return {?proto.swift.contract.v1.Contract}
 */
proto.swift.contract.v1.UpdateContractRequest.prototype.getContract = function() {
  return /** @type{?proto.swift.contract.v1.Contract} */ (
    jspb.Message.getWrapperField(this, proto.swift.contract.v1.Contract, 1));
};


/**
 * @param {?proto.swift.contract.v1.Contract|undefined} value
 * @return {!proto.swift.contract.v1.UpdateContractRequest} returns this
*/
proto.swift.contract.v1.UpdateContractRequest.prototype.setContract = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.UpdateContractRequest} returns this
 */
proto.swift.contract.v1.UpdateContractRequest.prototype.clearContract = function() {
  return this.setContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.UpdateContractRequest.prototype.hasContract = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.UpdateContractResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.UpdateContractResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.UpdateContractResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.UpdateContractResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
contract: (f = msg.getContract()) && proto.swift.contract.v1.Contract.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.UpdateContractResponse}
 */
proto.swift.contract.v1.UpdateContractResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.UpdateContractResponse;
  return proto.swift.contract.v1.UpdateContractResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.UpdateContractResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.UpdateContractResponse}
 */
proto.swift.contract.v1.UpdateContractResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.contract.v1.Contract;
      reader.readMessage(value,proto.swift.contract.v1.Contract.deserializeBinaryFromReader);
      msg.setContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.UpdateContractResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.UpdateContractResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.UpdateContractResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.UpdateContractResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContract();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.contract.v1.Contract.serializeBinaryToWriter
    );
  }
};


/**
 * optional Contract contract = 1;
 * @return {?proto.swift.contract.v1.Contract}
 */
proto.swift.contract.v1.UpdateContractResponse.prototype.getContract = function() {
  return /** @type{?proto.swift.contract.v1.Contract} */ (
    jspb.Message.getWrapperField(this, proto.swift.contract.v1.Contract, 1));
};


/**
 * @param {?proto.swift.contract.v1.Contract|undefined} value
 * @return {!proto.swift.contract.v1.UpdateContractResponse} returns this
*/
proto.swift.contract.v1.UpdateContractResponse.prototype.setContract = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.UpdateContractResponse} returns this
 */
proto.swift.contract.v1.UpdateContractResponse.prototype.clearContract = function() {
  return this.setContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.UpdateContractResponse.prototype.hasContract = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.contract.v1.UpdateSurchargeRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.UpdateSurchargeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.UpdateSurchargeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.UpdateSurchargeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.UpdateSurchargeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
surchargeList: jspb.Message.toObjectList(msg.getSurchargeList(),
    proto.swift.contract.v1.Surcharge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.UpdateSurchargeRequest}
 */
proto.swift.contract.v1.UpdateSurchargeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.UpdateSurchargeRequest;
  return proto.swift.contract.v1.UpdateSurchargeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.UpdateSurchargeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.UpdateSurchargeRequest}
 */
proto.swift.contract.v1.UpdateSurchargeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.contract.v1.Surcharge;
      reader.readMessage(value,proto.swift.contract.v1.Surcharge.deserializeBinaryFromReader);
      msg.addSurcharge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.UpdateSurchargeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.UpdateSurchargeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.UpdateSurchargeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.UpdateSurchargeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSurchargeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.contract.v1.Surcharge.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Surcharge surcharge = 1;
 * @return {!Array<!proto.swift.contract.v1.Surcharge>}
 */
proto.swift.contract.v1.UpdateSurchargeRequest.prototype.getSurchargeList = function() {
  return /** @type{!Array<!proto.swift.contract.v1.Surcharge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.contract.v1.Surcharge, 1));
};


/**
 * @param {!Array<!proto.swift.contract.v1.Surcharge>} value
 * @return {!proto.swift.contract.v1.UpdateSurchargeRequest} returns this
*/
proto.swift.contract.v1.UpdateSurchargeRequest.prototype.setSurchargeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.contract.v1.Surcharge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.Surcharge}
 */
proto.swift.contract.v1.UpdateSurchargeRequest.prototype.addSurcharge = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.contract.v1.Surcharge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.UpdateSurchargeRequest} returns this
 */
proto.swift.contract.v1.UpdateSurchargeRequest.prototype.clearSurchargeList = function() {
  return this.setSurchargeList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.contract.v1.UpdateSurchargeResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.UpdateSurchargeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.UpdateSurchargeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.UpdateSurchargeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.UpdateSurchargeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
surchargeList: jspb.Message.toObjectList(msg.getSurchargeList(),
    proto.swift.contract.v1.Surcharge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.UpdateSurchargeResponse}
 */
proto.swift.contract.v1.UpdateSurchargeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.UpdateSurchargeResponse;
  return proto.swift.contract.v1.UpdateSurchargeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.UpdateSurchargeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.UpdateSurchargeResponse}
 */
proto.swift.contract.v1.UpdateSurchargeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.contract.v1.Surcharge;
      reader.readMessage(value,proto.swift.contract.v1.Surcharge.deserializeBinaryFromReader);
      msg.addSurcharge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.UpdateSurchargeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.UpdateSurchargeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.UpdateSurchargeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.UpdateSurchargeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSurchargeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.contract.v1.Surcharge.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Surcharge surcharge = 1;
 * @return {!Array<!proto.swift.contract.v1.Surcharge>}
 */
proto.swift.contract.v1.UpdateSurchargeResponse.prototype.getSurchargeList = function() {
  return /** @type{!Array<!proto.swift.contract.v1.Surcharge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.contract.v1.Surcharge, 1));
};


/**
 * @param {!Array<!proto.swift.contract.v1.Surcharge>} value
 * @return {!proto.swift.contract.v1.UpdateSurchargeResponse} returns this
*/
proto.swift.contract.v1.UpdateSurchargeResponse.prototype.setSurchargeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.contract.v1.Surcharge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.Surcharge}
 */
proto.swift.contract.v1.UpdateSurchargeResponse.prototype.addSurcharge = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.contract.v1.Surcharge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.UpdateSurchargeResponse} returns this
 */
proto.swift.contract.v1.UpdateSurchargeResponse.prototype.clearSurchargeList = function() {
  return this.setSurchargeList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.ListSurchargesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.ListSurchargesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.ListSurchargesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.ListSurchargesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
pagination: (f = msg.getPagination()) && common_query_pb.Pagination.toObject(includeInstance, f),
filter: (f = msg.getFilter()) && proto.swift.contract.v1.SurchargeFilter.toObject(includeInstance, f),
sorting: (f = msg.getSorting()) && proto.swift.contract.v1.SurchargeSorting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.ListSurchargesRequest}
 */
proto.swift.contract.v1.ListSurchargesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.ListSurchargesRequest;
  return proto.swift.contract.v1.ListSurchargesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.ListSurchargesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.ListSurchargesRequest}
 */
proto.swift.contract.v1.ListSurchargesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_query_pb.Pagination;
      reader.readMessage(value,common_query_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = new proto.swift.contract.v1.SurchargeFilter;
      reader.readMessage(value,proto.swift.contract.v1.SurchargeFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new proto.swift.contract.v1.SurchargeSorting;
      reader.readMessage(value,proto.swift.contract.v1.SurchargeSorting.deserializeBinaryFromReader);
      msg.setSorting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.ListSurchargesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.ListSurchargesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.ListSurchargesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.ListSurchargesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_query_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.contract.v1.SurchargeFilter.serializeBinaryToWriter
    );
  }
  f = message.getSorting();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.swift.contract.v1.SurchargeSorting.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.common.Pagination pagination = 1;
 * @return {?proto.swift.common.Pagination}
 */
proto.swift.contract.v1.ListSurchargesRequest.prototype.getPagination = function() {
  return /** @type{?proto.swift.common.Pagination} */ (
    jspb.Message.getWrapperField(this, common_query_pb.Pagination, 1));
};


/**
 * @param {?proto.swift.common.Pagination|undefined} value
 * @return {!proto.swift.contract.v1.ListSurchargesRequest} returns this
*/
proto.swift.contract.v1.ListSurchargesRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.ListSurchargesRequest} returns this
 */
proto.swift.contract.v1.ListSurchargesRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.ListSurchargesRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SurchargeFilter filter = 2;
 * @return {?proto.swift.contract.v1.SurchargeFilter}
 */
proto.swift.contract.v1.ListSurchargesRequest.prototype.getFilter = function() {
  return /** @type{?proto.swift.contract.v1.SurchargeFilter} */ (
    jspb.Message.getWrapperField(this, proto.swift.contract.v1.SurchargeFilter, 2));
};


/**
 * @param {?proto.swift.contract.v1.SurchargeFilter|undefined} value
 * @return {!proto.swift.contract.v1.ListSurchargesRequest} returns this
*/
proto.swift.contract.v1.ListSurchargesRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.ListSurchargesRequest} returns this
 */
proto.swift.contract.v1.ListSurchargesRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.ListSurchargesRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SurchargeSorting sorting = 3;
 * @return {?proto.swift.contract.v1.SurchargeSorting}
 */
proto.swift.contract.v1.ListSurchargesRequest.prototype.getSorting = function() {
  return /** @type{?proto.swift.contract.v1.SurchargeSorting} */ (
    jspb.Message.getWrapperField(this, proto.swift.contract.v1.SurchargeSorting, 3));
};


/**
 * @param {?proto.swift.contract.v1.SurchargeSorting|undefined} value
 * @return {!proto.swift.contract.v1.ListSurchargesRequest} returns this
*/
proto.swift.contract.v1.ListSurchargesRequest.prototype.setSorting = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.contract.v1.ListSurchargesRequest} returns this
 */
proto.swift.contract.v1.ListSurchargesRequest.prototype.clearSorting = function() {
  return this.setSorting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.contract.v1.ListSurchargesRequest.prototype.hasSorting = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.contract.v1.ListSurchargesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.contract.v1.ListSurchargesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.contract.v1.ListSurchargesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.contract.v1.ListSurchargesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.ListSurchargesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
surchargesList: jspb.Message.toObjectList(msg.getSurchargesList(),
    proto.swift.contract.v1.Surcharge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.contract.v1.ListSurchargesResponse}
 */
proto.swift.contract.v1.ListSurchargesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.contract.v1.ListSurchargesResponse;
  return proto.swift.contract.v1.ListSurchargesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.contract.v1.ListSurchargesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.contract.v1.ListSurchargesResponse}
 */
proto.swift.contract.v1.ListSurchargesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.contract.v1.Surcharge;
      reader.readMessage(value,proto.swift.contract.v1.Surcharge.deserializeBinaryFromReader);
      msg.addSurcharges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.contract.v1.ListSurchargesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.contract.v1.ListSurchargesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.contract.v1.ListSurchargesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.contract.v1.ListSurchargesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSurchargesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.contract.v1.Surcharge.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Surcharge surcharges = 1;
 * @return {!Array<!proto.swift.contract.v1.Surcharge>}
 */
proto.swift.contract.v1.ListSurchargesResponse.prototype.getSurchargesList = function() {
  return /** @type{!Array<!proto.swift.contract.v1.Surcharge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.contract.v1.Surcharge, 1));
};


/**
 * @param {!Array<!proto.swift.contract.v1.Surcharge>} value
 * @return {!proto.swift.contract.v1.ListSurchargesResponse} returns this
*/
proto.swift.contract.v1.ListSurchargesResponse.prototype.setSurchargesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.contract.v1.Surcharge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.contract.v1.Surcharge}
 */
proto.swift.contract.v1.ListSurchargesResponse.prototype.addSurcharges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.contract.v1.Surcharge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.contract.v1.ListSurchargesResponse} returns this
 */
proto.swift.contract.v1.ListSurchargesResponse.prototype.clearSurchargesList = function() {
  return this.setSurchargesList([]);
};


goog.object.extend(exports, proto.swift.contract.v1);
